<!--
 * @Author: cuihaopeng cuihaopeng@slodon.cn
 * @Date: 2023-09-14 09:16:36
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @LastEditTime: 2023-09-14 10:20:40
 * @FilePath: /weichao_pc/src/views/member/gift/bindGiftCard.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 绑定礼品卡页面 -->
<template>
  <div class="sld_gift_card_wrapper">
    <MemberTitle memberTitle="余额/礼品卡" memberPath="/member/gift/gift" memberTitleS="绑定礼品卡" style="padding-left:20px">
    </MemberTitle>
    <div class="gift_card_wrapper">
      <div class="gift_card_content">
        <div class="top_tips">
          <div class="tip_item">
            <span>1</span>
            <span class="tip_text">刮开礼品卡</span>
          </div>
          <div class="tip_item">
            <span>2</span>
            <span class="tip_text">输入卡密</span>
          </div>
          <div class="tip_item">
            <span>3</span>
            <span class="tip_text">绑定成功</span>
          </div>
        </div>
        <div class="title">卡密</div>
        <el-input class="input_card" type="number" v-model="giftCard" placeholder="请输入卡密"></el-input>
        <div class="bind_btn flex_row_center_center" @click="bind">绑定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import {  ElMessage } from "element-plus";
import MemberTitle from '@/components/MemberTitle';
import { reactive, onMounted, getCurrentInstance, ref } from 'vue'
export default {
  name: 'bindGiftCard',
  components: {
    MemberTitle,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const L = proxy.$getCurLanguage()
    const router = useRouter()
    const giftCard = ref('')
    const bind = () =>{
        if(!giftCard.value){
            ElMessage.warning('请输入卡密')
            return;
        }
         proxy.$get('v3/promotion/front/gift/bind', {cartPassword:giftCard.value}).then(res => {
        if (res.state == 200) {
          ElMessage.success(res.msg)
          router.push({
                path: "/member/gift/gift",
            });
        }else {
            ElMessage.warning(res.msg)
        }
      })
    }
    onMounted(() => {
    })

    return {
      L,
      giftCard,
      bind,
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/member/giftCard.scss';
</style>